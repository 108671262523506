import React from "react";
import styled from "styled-components";

const BackgroundContainer = styled.div`
  position: relative;
  background-image: url("/imgs/bg.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    border-radius: 50%;
    margin: 10px;
    img {
      padding: 0;
      max-height: 50px;
    }
  }

  @media (max-width: 600px) {
    a img {
      max-height: 40px;
    }
  }
`;

const TransparentText = styled.h1`
  font-size: clamp(
    4rem,
    20vw,
    6rem
  ); /* Scales between 2rem and 6rem based on screen width */
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  background-image: url("/imgs/bg.png"); /* Use the same background image */
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Locks the background to the viewport */
  -webkit-background-clip: text;
  color: transparent; /* Makes the text itself transparent */
  letter-spacing: 5px;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: #edc704;
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
      1px 1px 0 black;
    margin: 0;
    font-size: 6rem;
    margin-top: auto;
  }

  img {
    max-height: 140px;
    padding: 10px;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    h1 {
      font-size: 3rem;
    }
    img {
      max-height: 120px;
    }
  }
`;

const SubText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 40px;
  padding: 10px 20px;

  h2 {
    margin: 0;
    text-decoration: underline;
  }

  p {
    text-align: center;
    padding: 5px;
    font-size: 1rem;
    // max-width: 400px;
    color: #dc5f3c;
    margin: 0;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export default function App() {
  return (
    <BackgroundContainer>
      <Overlay>
        <TransparentText>coming soon, Bitch.</TransparentText>
        <Banner>
          <img
            src="/imgs/logo_512.png"
            alt="Shitcoin Tycoons Logo"
            loading="lazy"
          />
          <h1>Shitcoin Tycoons</h1>
        </Banner>
        <SubText>
          <h2>The Crypto Degen Card Game</h2>
          <p>
            Coming to market. Kickstarter and socials OTW. Check back for the
            greatest crypto degen card game ever made.
          </p>
        </SubText>
        <a
          href="https://twitter.com/ShitcoinTycoons"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/imgs/x.png"
            alt="Shitcoin Tycoons Twitter X Link"
            loading="lazy"
          />
        </a>
      </Overlay>
    </BackgroundContainer>
  );
}
